
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";
import Row from "@/components/base/common/Row.vue";
import ClaimLink from "@/views/claim/ClaimLink.vue";
import ClaimStatusMsg from "@/views/claim/ClaimStatusMsg.vue";

export default defineComponent({
  name: "ClaimCard",
  components: {ClaimStatusMsg, ClaimLink, Row},
  props: {
    claim: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
